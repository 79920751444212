.social__proof__main{
    max-width: 400px;
    margin: 0 auto;
}
.sec__title{
    color: rgb(73, 201, 95);
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 900;
    margin-bottom: 6px;
}
.sec__sub__title{
    color: #454e57;
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 8px;
    text-align: center;
}
.social__proof__img{
    display: block;
    margin: 18px auto 38px;
    max-height: 370px;
}
.shadow__btn{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 560px;
    margin: 0 auto;
    display: block;
}