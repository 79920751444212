.current__mind__state__wrap{
    padding: 0 18px;
}
.sec__title {
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto 6px;
    display: block;
    color: rgb(41, 46, 51);
    font-size: 24px;
}
.sec__sub__title {
    text-align: center;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 4px;
    max-width: 500px;
    margin: 0 auto;
    display: block;
    color: rgb(41, 46, 51);
    font-size: 16px;
}
.goals__list {
    max-width: 592px;
    margin: 0 auto;
    padding-top: 24px;
}
.goal__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    min-height: 80px;
    overflow: hidden;
    height: 90px;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    padding: 14px 0px 14px 18px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: 0.3s all ease;
}
.goal__item__title {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 18px;
}
.goal__item__img img {
    max-height: 95px;
}
.goal__item.active{
    background-color: #49c95f;
}
.goal__item.active .goal__item__title{
    color: #fff;
}