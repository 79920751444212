.mindfulness__main{
    margin: 0 auto;
    max-width: 400px;
    padding: 28px 16px 124px;
}
.sec__title{
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 12px;
}
.mindfulness__graph__container{
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0,0,0,.08), 0 2px 34px rgba(0,0,0,.05);
}
.mindfulness__graph__header{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 12px 12px 3px;
}
.mindfulness__graph__header__title{
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
.mindfulness__graph__header__def{
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
}
.svg__container{
    position: relative;
}
.svg__line__wrap{
    left: 21px;
    position: absolute;
    right: 21px;
    top: 28px;
}
.svg__line__handle{
    height: 25px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    transition: left 3s ease-in;
}
.svg__line__handle__tooltip{
    background-color: #454e57;
    border-radius: 4px;
    left: 50%;
    padding: 4px 11px;
    position: absolute;
    top: 0;
    transform: translate(-50%,calc(-100% - 10px));
    white-space: nowrap;
}
.svg__line__handle__tooltip__caption{
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 16px;
}
.svg__line__handle__tooltip__caption:after {
    background-color: #454e57;
    border-radius: 2px;
    bottom: 0;
    content: "";
    height: 8px;
    left: 50%;
    position: absolute;
    transform: translate(-50%,4px) rotate(45deg);
    width: 8px;
}
.svg__line__handle__round{
    background: hsla(48,95%,85%,.7);
    border: 2px solid #f1d148;
    border-radius: 50%;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
}
.svg__line__handle__round:after {
    background: #f1d148;
    border-radius: 50%;
    content: "";
    height: 15px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 15px;
}
.svg__graph__footer__label__wrap{
    align-items: center;
    bottom: 11px;
    display: flex;
    justify-content: space-around;
    left: 0;
    position: absolute;
    right: 0;
}
.svg__graph__footer__label{
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.svg__graph__footer__label.active{
    color: #000;
}
.mindfulness__summary__lists{
    padding-top: 18px;
    padding-bottom: 18px;
}
.mindfulness__summary__item{
    display: flex;
    align-items: center;
    padding: 8px 0;
}
.mindfulness__summary__item__img{
    margin-right: 12px;
}
.mindfulness__summary__item__img img{
    width: 27px;
    height: auto;
}
.beginner_level__img img{
    padding: 3px;
}
.mindfulness__summary__item__head{
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
}
.mindfulness__summary__item__title{
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
.bmi__note__wrap {
    background-color: #f9f9fa;
    border-radius: 8px;
    display: flex;
    padding: 8px;
    margin-top: 28px;
    max-width: 592px;
    margin: 0 auto;
}
.bmi__note__wrap.warn{
    background-color: #FEF5F5;
}
.bmi__note__img {
    margin-right: 4px;
}
.bmi__note__img img {
    max-height: 20px;
}
.bmi__note__title {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}
.bmi__note__wrap.success .bmi__note__title span{
    color: #49C95F;
}
.bmi__note__sub__title {
    color: rgb(41, 46, 51);
    font-size: 12px;
    line-height: 16px;
}
.shadow__btn {
    /* box-shadow: 0 27px 13px 47px #fff;
    background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%); */
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    /* animation: 2s ease 0s infinite normal none running pulsar; */
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 400px;
}
.gender__select__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}
.med__sum__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.med__sum img{
    max-height: 180px;
}