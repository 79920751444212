.seek__meditation__benefit__wrap{
    padding: 0 18px 120px;
}
.sec__title {
    color: rgb(41, 46, 51);
    font-size: 24px;
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto 12px;
    display: block;
}
.focus__wrap {
    max-width: 365px;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 24px;
}
.goal__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    margin-bottom: 12px;
    max-height: 52px;
    min-height: 52px;
    padding: 18px;
    margin-right: 12px;
    cursor: pointer;
}
.goal__item__title {
    color: #454e57;
    font-weight: 700;
    font-size: 14px;
}

.shadow__btn {
    /* box-shadow: 0 27px 13px 47px #fff; */
    background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%); 
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 592px;
    cursor: pointer;
}
.gender__select__btn:disabled{
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}
.goal__item.active{
    background-color: #49c95f;
}
.goal__item.active .goal__item__title{
    color: #fff;
}
@media(max-width: 428px){
    .goal__item{
        padding: 9.09px;
    }
    .focus__wrap {
        max-width: 330px;
    }
}