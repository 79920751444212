.quiz__reviews__main{
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 600px;
    padding: 32px 0 0;
    position: relative;
    width: 100%;
}
.round__loader{
    fill: transparent;
}
.loader__text{
    font-size: 40px;
    font-weight: 900;
}
.round__loader__stroke{
    transition: stroke-dashoffset 0.3s ease 0s;
    transform: rotate(-90deg);
    transform-origin: center;
}
.plan__note{
    color: rgb(41, 46, 51);
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    margin-bottom: 35px;
}
.sec__title{
    color: rgb(73, 201, 95);
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    font-weight: 900;
    padding: 0 16px;
}
.sec__sub__title{
    color: rgb(73, 201, 95);
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    margin-top: 4px;
    margin-bottom: 24px;
}
.slider__container{
    max-width: 560px;
}
.slide__item{
    border: 1px solid #d4dae0;
    border-radius: 12px;
    box-sizing: border-box;
    /* margin: 0 6px 12px; */
    padding: 16px;
}
.review__head{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.review__name__wrap{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.trustpilot__stars img{
    max-height: 15px;
}
.truspilot__logo__img img{
    max-height: 24px;
}
.review__text{
    color: rgb(41, 46, 51);
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
}
.review__initials{
    background-color: #E5F1FB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 12px;
    color: #204482;
    font-weight: 500;
    margin-right: 8px;
}
.review__initials img{
    max-height: 32px;
}
@media(max-width: 576px){
    .slider__container{
        max-width: 100%;
    }
}