.steps__header__wrap{
    padding: 18px;
}
.steps__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
}
.back__btn{
    background: transparent;
    border: none;
    cursor: pointer;
}
.steps__header--logo img{
    max-height: 30px;
}
.steps__count__wrap{
    font-size: 12px;
    font-weight: 500;
}
.steps__count, .steps__count__separator{
    color: #49c95f;
}
.steps__progress{
    background-color: rgba(212,218,224,.5);
    border-radius: 5px;
    height: 6px;
    position: relative;
    width: 100%;
}
.steps__progress--fill{
    background-color: #49c95f;
    border-radius: 5px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width .2s linear;
    width: 10%;
}