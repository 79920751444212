.ControlImportantThings__wrap{
    padding: 0 18px 68px;
}
.sec__title {
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto;
    display: block;
    color: rgb(41, 46, 51);
    font-size: 24px;
}
.goals__list {
    max-width: 592px;
    margin: 0 auto;
    padding-top: 24px;
}
.goal__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    min-height: 70px;
    overflow: hidden;
    height: 70px;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    padding: 14px 18px 14px 18px;
    margin-bottom: 12px;
    cursor: pointer;
}
.goal__item__title {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 18px;
}
.goal__item.active{
    background-color: #49c95f;
}
.goal__item.active .goal__item__title{
    color: #fff;
}
.bmi__note__wrap {
    background-color: #f9f9fa;
    border-radius: 8px;
    display: flex;
    padding: 8px;
    max-width: 592px;
    margin: 28px auto 0;
}
.bmi__note__img {
    margin-right: 4px;
}
.bmi__note__img img {
    max-height: 20px;
}
.bmi__note__title {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}
.bmi__note__sub__title {
    color: rgb(41, 46, 51);
    font-size: 12px;
    line-height: 16px;
}