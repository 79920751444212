.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
}
.logo__header-img{
    max-height: 28px;
}
.login__anchor{
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 16px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
}