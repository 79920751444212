.sec__email__wrap{
    margin: 0 auto;
    max-width: 448px;
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
}
.sec__title{
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
}
.sec__title span{
    color: #49c95f;
}
.email__input__container{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.email__input{
    background-color: #f3f5f7;
    border: 1px solid #d4dae0;
    border-radius: 12px;
    box-sizing: border-box;
    color: #454e57;
    font-size: 16px;
    height: 54px;
    line-height: 22px;
    padding: 0 24px 0 40px;
    transition: .3s;
    width: 100%;
    outline: none;
}
.email__input:focus {
    background-color: #fff;
    border-color: #454e57;
}
.email__input.invalid{
    background-color: #fef5f5;
    border-color: #ff4f64;
}
.padlock__image{
    left: 13px;
    position: absolute;
}
.round__tick__image{
    right: 12px;
    position: absolute;
}
.email__invalid__icon{
    right: 12px;
    position: absolute;
    border: 2px solid #ff4f64;
    border-radius: 50%;
    color: #ff4f64;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 16px;
    justify-content: center;
    width: 16px;
}
.email__validity__alert__container{
    margin-bottom: 16px;
    min-height: 16px;
    padding: 0 12px;
    width: 100%;
}
.email__valid__alert{
    font-size: 12px;
    line-height: 16px;
    color: #49c95f;
}
.email__invalid__alert{
    font-size: 12px;
    line-height: 16px;
    color: #ff4f64;
}
.lock__note__wrap{
    align-items: center;
    display: flex;
    margin-bottom: 24px;
}
.padlock__img{
    margin-right: 5px;
    min-width: fit-content;
    padding: 5px;
}
.padlock__img img{
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    max-width: 22px;
}
.lock__note{
    color: rgb(134, 150, 166);
    font-size: 12px;
    line-height: 16px;
}
.lock__note a{
    color: inherit;
    text-decoration: underline;
}
.shadow__btn {
    /* box-shadow: 0 27px 13px 47px #fff;
    background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%); */
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    /* animation: 2s ease 0s infinite normal none running pulsar; */
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 400px;
}
.gender__select__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}