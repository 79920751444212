.midTwentyThree__wrap {
    padding: 22px;
    background-color: #F3F5F7;
}
.midTwentyThree__main{
    text-align: center;
    max-width: 560px;
    display: block;
    margin: 0 auto;
}
.banner__image{
    margin-bottom: 24px;
}
.banner__image img{
    max-height: 138px;
}
.title{
    font-size: 22px;
    padding-bottom: 24px;
    color: #454E57;
}
.text{
    font-size: 20px;
    color: #454E57;
}
.shadow__btn {
    /* box-shadow: 0 27px 13px 47px #fff;
    background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%); */
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    /* animation: 2s ease 0s infinite normal none running pulsar; */
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 592px;
}
.gender__select__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}