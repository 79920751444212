.number__rating__wrap {
    margin: 0 auto;
    max-width: 411px;
    padding: 0 18px;
}
.sec__title {
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    padding-bottom: 12px;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto;
    display: block;
}
.sec__sub__title {
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
    font-weight: 400;
    max-width: 500px;
    margin: 0 auto;
    display: block;
}
.number__rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.unit__rate {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 54px;
    padding: 0;
    width: 59px;
    margin-bottom: 12px;
    border-radius: 12px;
    height: auto;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}
.unit__rate.active{
    background-color: #49c95f;
    color: #fff;
}
.number__rating__label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    color: rgb(41, 46, 51);
    padding-top: 10px;
}