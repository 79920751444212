@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}
.is__full-height{
  min-height: 100vh;
}
.quiz__reivews__slider__container .slick-slide{
  padding: 8px;
}
html {
  scroll-behavior: smooth;
}

@media(max-width: 375px){
  .recharts-surface{
    max-width: 90%;
  }
}
.stress-reduction-composed-chart .recharts-surface .transOpacity{
  transition: 0.5s all ease-in-out;
}
.stress-reduction-composed-chart .recharts-surface:hover .transOpacity{
  opacity: 0;
}
