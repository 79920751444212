.stress__reduction__wrap{
    padding: 0 18px 120px;
}
.sec__title {
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    padding-bottom: 12px;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto;
    display: block;
    color: rgb(41, 46, 51);
    font-size: 24px;
}
.green__highlight {
    color: #49c95f;
}
.text {
    color: rgb(69, 78, 87);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
}
.text__main {
    color: rgb(41, 46, 51);
    text-align: center;
    font-size: 21px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 12px;
    text-decoration: underline;
}
.graph__container {
    margin: 0 auto 28px;
    max-width: 400px;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px; */
    /* border-radius: 12px; */
    overflow: hidden;
}
.graph__container__main {
    margin: 0 auto 28px;
    max-width: 370px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px;
    border-radius: 12px;
    overflow: hidden;
}
.graph__container img {
    max-width: 100%;
}
.shadow__btn {
    box-shadow: 0 27px 13px 47px #fff;
    /* background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%); */
    background: #fff;
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 592px;
}
.gender__select__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}