.sec__title {
    color: rgb(69, 78, 87);
    text-align: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    padding-bottom: 12px;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto;
    display: block;
}
.sec__sub__title {
    color: rgb(134, 150, 166);
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 4px;
    max-width: 500px;
    margin: 0 auto;
    display: block;
}
.gender__action__wrap {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 24px;
}
.gender__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18px;
    min-width: 340px;
}
.gender__img {
    max-height: 286px;
    margin-bottom: 28px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
}
@keyframes pulsar{
    0% {
        box-shadow: 0 0 0 0 #49c95f;
    }

    to {
        box-shadow: 0 0 0 14px transparent;
    }
}
.gender__select__btn.female__btn{
    background-color: #ff964f;
    box-shadow: rgb(255, 150, 79) 0px 0px 0px 0px;
    animation: 2s ease 0s infinite normal none running pulsar-female;
}
@keyframes pulsar-female{
    0% {
        box-shadow: 0 0 0 0 #ff964f;
    }

    to {
        box-shadow: 0 0 0 14px transparent;
    }
}
.gender__select__btn:hover{
    background-color: rgb(98, 208, 117);
}
.gender__footer{
    max-width: 410px;
    text-align: center;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 18px;
    color: #8696a6;
    opacity: 0.8;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.gender__footer a{
    color: #49c95f;
    text-decoration: none;
}
.homepage{
    padding: 0 16px;
}
@media (max-width: 576px){
    .gender__item{
        min-width: auto;
    }
    .gender__img {
        max-height: 190px;
    }
    .gender__select__btn{
        padding: 14px;
        min-height: auto;
        font-size: 15px;
    }
}