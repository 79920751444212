.activity__level__wrap{
    padding: 0 18px 120px;
}
.sec__title {
    color: rgb(41, 46, 51);
    font-size: 24px;
    text-align: center;
    line-height: 36px;
    font-weight: 700;
    padding-top: 8px;
    max-width: 560px;
    margin: 0 auto 12px;
    display: block;
}
.goals__list {
    max-width: 592px;
    margin: 0 auto;
    padding-top: 24px;
}
.goal__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    min-height: 90px;
    overflow: hidden;
    height: 90px;
    border: 1px solid rgb(212, 218, 224);
    background-color: rgb(255, 255, 255);
    padding: 14px 18px 14px 18px;
    margin-bottom: 12px;
    cursor: pointer;
}
.medium__text {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
}
.small__text {
    color: rgb(69, 78, 87);
    font-size: 14px;
    line-height: 20px;
}
.activity__img {
    padding-right: 18px;
}
.activity__img img {
    max-height: 32px;
}
.goal__item.active{
    background-color: #49c95f;
}
.goal__item.active .medium__text{
    color: #fff;
}
.goal__item.active .small__text{
    color: #fff;
}
.bmi__note__wrap {
    background-color: #f9f9fa;
    border-radius: 8px;
    display: flex;
    padding: 8px;
    margin-top: 28px;
    max-width: 592px;
    margin: 0 auto;
}
.bmi__note__wrap.success{
    background-color: #F2FCF4;
}
.bmi__note__wrap.moderate{
    background-color: #F0F5FE;
}
.moderate__highlight{
    color: #1E75FF;
}
.bmi__note__img {
    margin-right: 4px;
}
.bmi__note__img img {
    max-height: 20px;
}
.bmi__note__title {
    color: rgb(41, 46, 51);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}
.bmi__note__wrap.success .bmi__note__title span{
    color: #49C95F;
}
.bmi__note__sub__title {
    color: rgb(41, 46, 51);
    font-size: 12px;
    line-height: 16px;
}
.shadow__btn {
    /* box-shadow: 0 27px 13px 47px #fff; */
    background: linear-gradient(0deg,#fff 0,#fff 65%,hsla(0,0%,100%,0) 100%);
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 18px;
}
.gender__select__btn {
    position: relative;
    cursor: pointer;
    background-color: #49c95f;
    border-radius: 12px;
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    min-height: 64px;
    word-break: break-word;
    animation: 2s ease 0s infinite normal none running pulsar;
    box-shadow: rgb(73, 201, 95) 0px 0px 0px 0px;
    font-family: Roboto, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.3s all ease;
    max-width: 592px;
}
.gender__select__btn:disabled {
    cursor: not-allowed;
    background-color: rgb(164, 233, 176);
}